'use client'; // https://www.blocknotejs.org/docs/advanced/nextjs - can't use blocknote in server side
import React, { useEffect, useMemo, useState } from 'react';
import { type Block } from '@blocknote/core';
import { useCreateBlockNote } from '@blocknote/react';
import clsx from 'clsx';
import { getCldImageUrl } from 'next-cloudinary';
import { BlockHeading } from '../../typography';
import { BLOCK_WIDTH_CLASS } from '../constants';
import { ImageMeta } from '../types';
import CloudinaryImage from '../ui/cloudinary-image';

export interface TextBlockProps {
  content: {
    description?: Block[];
    heading?: string;
    image?: ImageMeta | null;
    variant: 'standard_text' | 'text_with_left_image' | 'text_with_right_image';
  };
  style?: React.CSSProperties;
}

// --------------------- TEXT BLOCK VARIANT RENDERER ---------------------
export const TextBlock: React.ComponentType<TextBlockProps> = ({
  content,
  style,
}) => {
  if (
    content.variant === 'text_with_left_image' ||
    content.variant === 'text_with_right_image'
  ) {
    return <TextBlockWithImage content={content} style={style} />;
  }
  return <StandardTextBlock content={content} style={style} />;
};

// --------------------- VARIANTS ---------------------
// STANDARD TEXT BLOCK
export const StandardTextBlock: React.ComponentType<TextBlockProps> = ({
  content,
  style,
}) => {
  const [descriptionHTML, setDescriptionHTML] = useState<string>('');
  const editor = useCreateBlockNote();

  useEffect(() => {
    const convertDescriptionToHTML = async () => {
      if (content.description) {
        const html = await editor.blocksToHTMLLossy(content.description);
        setDescriptionHTML(html);
      }
    };

    convertDescriptionToHTML();
  }, [editor, content.description]);

  return (
    <div className={clsx(BLOCK_WIDTH_CLASS, 'flex flex-col gap-3.5')}>
      {content.heading !== '' && (
        <BlockHeading className="max-w-4xl">{content.heading}</BlockHeading>
      )}
      {descriptionHTML && (
        <div
          dangerouslySetInnerHTML={{ __html: descriptionHTML }}
          className="blocknote prose max-w-4xl font-body text-lg leading-normal text-gray-700 "
          style={style}
        />
      )}
    </div>
  );
};

// TEXT BLOCK WITH LEFT || RIGHT IMAGE
export const TextBlockWithImage: React.ComponentType<TextBlockProps> = ({
  content,
  style,
}) => {
  const [descriptionHTML, setDescriptionHTML] = useState<string>('');
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

  const editor = useCreateBlockNote();

  function isVariantLeft(variant: string) {
    return variant === 'text_with_left_image';
  }
  const isLeft = isVariantLeft(content.variant);

  useEffect(() => {
    if (!content.image) return;

    const { cloudinaryPublicId, cloudinaryUrl, height, width } = content.image;
    if (height && width) {
      setDimensions({ height, width });
    } else if (cloudinaryUrl) {
      const img = new Image();
      img.src = cloudinaryUrl as string;
      img.onload = () => {
        setDimensions({ height: img.height, width: img.width });
      };
    } else if (cloudinaryPublicId) {
      const img = new Image();
      img.src = getCldImageUrl({
        height: img.height,
        src: cloudinaryPublicId,
        width: img.width,
      });
    }
  }, [content.image]);

  const aspectRatioClass =
    dimensions.height && dimensions.width
      ? `aspect-[${dimensions.width}/${dimensions.height}]`
      : '';

  const objectFitClass =
    dimensions.height > 720 ? 'object-contain' : 'object-cover';

  const maybeAddRoundedCornersToImageUrl = useMemo(() => {
    if (!content.image || !content.image.cloudinaryUrl) return '';
    if (content.image.cloudinaryUrl) {
      const url = content.image.cloudinaryUrl;

      if (objectFitClass !== 'object-contain') {
        return url;
      }

      const searchPattern = 'upload/';
      const index = url.indexOf(searchPattern);

      if (index === -1) {
        return url;
      }

      const prefix = url.slice(0, index + searchPattern.length);
      const suffix = url.slice(index + searchPattern.length);

      return `${prefix}r_16/${suffix}`;
    }
    if (content.image.cloudinaryPublicId) {
      return getCldImageUrl({
        radius: '16',
        src: content.image.cloudinaryPublicId,
      });
    }
    return '';
  }, [content, objectFitClass]);

  useEffect(() => {
    const convertDescriptionToHTML = async () => {
      if (content.description) {
        const html = await editor.blocksToHTMLLossy(content.description);
        setDescriptionHTML(html);
      }
    };

    convertDescriptionToHTML();
  }, [editor, content.description]);

  return (
    <div
      className={clsx(
        BLOCK_WIDTH_CLASS,
        'flex flex-col items-start justify-between gap-8 md:grid md:grid-cols-2 md:gap-20'
      )}
    >
      {!!dimensions.width && !!dimensions.height && !!content.image && (
        <div
          className={clsx(
            'relative flex w-full justify-center rounded-lg',
            aspectRatioClass,
            isLeft ? 'order-1' : 'order-3'
          )}
        >
          <CloudinaryImage
            alt={content.image.alt || 'Untitled image'}
            className={clsx(`max-h-[720px] rounded-lg `, objectFitClass)}
            height={dimensions.height}
            sizes="100vw"
            src={maybeAddRoundedCornersToImageUrl}
            width={dimensions.width}
          />
        </div>
      )}
      {/* AS: my-auto here instead of items-center, as we want it centered when not a lot of content next to image, but top aligned when lots of content next to image */}
      <div className="order-2 my-auto flex flex-col gap-3.5">
        {content.heading !== '' && (
          <BlockHeading>{content.heading}</BlockHeading>
        )}
        {descriptionHTML && (
          <div
            dangerouslySetInnerHTML={{ __html: descriptionHTML }}
            className="blocknote prose font-body text-lg leading-normal text-gray-700"
            style={style}
          />
        )}
      </div>
    </div>
  );
};
