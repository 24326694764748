import React from 'react';
import {
  MegaphoneIcon,
  DocumentChartBarIcon,
  PresentationChartLineIcon,
  NewspaperIcon,
  StarIcon,
  BriefcaseIcon,
  UsersIcon,
  DocumentIcon,
  GlobeAltIcon,
  FireIcon,
  ChartBarIcon,
  CurrencyDollarIcon,
  CurrencyPoundIcon,
  EnvelopeIcon,
  VideoCameraIcon,
  QuestionMarkCircleIcon,
  ArrowRightIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react-v2/24/solid';
import clsx from 'clsx';
import Link from 'next/link';
import { Card } from '../../card';
import { Typography, BlockHeading } from '../../typography';

interface QuickLink {
  icon: string;
  label: string;
  openInNewTab: boolean;
  scrollToBlock: string;
  url: string;
}

interface QuickLinksBlockProps {
  content: {
    heading: string;
    invertColors: boolean;
    links: QuickLink[];
  };
}

const iconComponents = {
  BriefcaseIcon,
  ChartBarIcon,
  CurrencyDollarIcon,
  CurrencyPoundIcon,
  DocumentChartBarIcon,
  DocumentIcon,
  EnvelopeIcon,
  FireIcon,
  GlobeAltIcon,
  MegaphoneIcon,
  NewspaperIcon,
  PresentationChartLineIcon,
  StarIcon,
  UsersIcon,
  VideoCameraIcon,
};

export const QuickLinksBlock: React.FC<QuickLinksBlockProps> = ({
  content,
}) => {
  const { heading, invertColors, links } = content;
  return (
    <div className="mx-auto w-full max-w-screen-xl space-y-4 px-4 md:px-6">
      {heading && heading.length > 0 && <BlockHeading>{heading}</BlockHeading>}

      <div className="grid gap-4 md:grid-cols-2 md:gap-6 lg:grid-cols-4">
        {!!links &&
          links.length > 0 &&
          links.map((link, index) => (
            <QuickLinkCard key={index} {...link} invertColors={invertColors} />
          ))}
      </div>
    </div>
  );
};

interface QuickLinkCardProps extends QuickLink {
  invertColors: boolean;
}

const QuickLinkCard: React.FC<QuickLinkCardProps> = ({
  icon,
  invertColors,
  label,
  openInNewTab,
  scrollToBlock,
  url,
}) => {
  const IconComponent =
    iconComponents[icon as keyof typeof iconComponents] ||
    QuestionMarkCircleIcon;

  // Handle the '/home' URL case - and a catch for when url is empty
  const adjustedUrl = url === '/home' ? '/' : url ? url : '/';

  const linkProps = {
    href: scrollToBlock ? `${adjustedUrl}#${scrollToBlock}` : adjustedUrl,
    ...(openInNewTab ? { rel: 'noopener noreferrer', target: '_blank' } : {}),
  };

  const ariaLabel = `${label}${openInNewTab ? ' (opens in new tab)' : ''}`;

  return (
    <Link {...linkProps} aria-label={ariaLabel}>
      <Card className="group flex items-center !rounded-lg">
        <div
          aria-hidden="true"
          className={clsx(
            'flex items-center justify-center rounded-l-lg p-4',
            invertColors ? 'bg-company-primary-text' : 'bg-company-primary'
          )}
        >
          <IconComponent
            className={clsx(
              'h-6 w-6 transition-transform duration-300 group-hover:scale-125',
              invertColors
                ? 'text-company-primary'
                : 'text-company-primary-text'
            )}
          />
        </div>
        <Typography
          className="mx-4 grow font-body font-normal"
          variant="button"
        >
          {label}
        </Typography>
        <div
          aria-hidden="true"
          className="flex items-center justify-center pr-3"
        >
          {openInNewTab ? (
            <ArrowTopRightOnSquareIcon className="size-4 text-gray-500 transition-transform group-hover:translate-x-1/4" />
          ) : (
            <ArrowRightIcon className="size-4 text-gray-500 transition-transform group-hover:translate-x-1/4" />
          )}
        </div>
      </Card>
    </Link>
  );
};
